<template>
  <div v-if="!loadingMsg">
    <div v-if="!showApptConfirmation">
      <b-button
        v-if="!eventData.confirmation && !eventData.consultation"
        @click="showApptConfirmation = !showApptConfirmation"
        variant="secondary"
        class="mb-3"
        block
        >Session Confirmation</b-button
      >
      <b-alert
        v-if="eventData.confirmation"
        variant="success"
        class="my-3 text-center"
        show
      >
        <i class="fa fa-check"></i> Session Confirmed
      </b-alert>
      <b-row>
        <div class="col-md-6">
          <p>
            <span
              v-if="
                eventData.statusLabel &&
                eventData.statusLabel.includes(
                  'new',
                  'updated',
                  'complete',
                  'in progress'
                )
              "
              class="h5"
              >Date:
              <strong>{{ $d(`${eventData.start}`).format('MM/DD/YY h:mma') }}</strong
              ><br
            /></span>
            <span class="h4">Ref ID: #{{ eventData.id }}</span
            ><br />
            <span class="h4"
              >Student: {{ eventData.client.fname }} {{ eventData.client.lname }}</span
            ><br />
            <span class="h4">Contact: {{ pcName }}</span
            ><br />
            <span class="h4">Contact Phone: {{ pcPhone }}</span>
          </p>
        </div>
        <div class="col-md-6">
          <p>
            Created On:
            <strong>{{ $d(eventData.dateCreated).format('MM/DD/YY h:mma') }}</strong
            ><br />
            Updated On:
            <strong>{{ $d(eventData.lastUpdated).format('MM/DD/YY h:mma') }}</strong
            ><br />
            Director: <strong>@todo ({{ eventData.franchiseCode }})</strong><br />
            School: {{ eventData.school }}
          </p>
        </div>
        <!-- div class="col-md-6">
          <p class="text-right">ONLINE DATA HERE</p>
        </div -->
      </b-row>

      <b-row>
        <div class="col-md-6">
          <h3 class="my-3">Client Details</h3>
          <div class="apptDet">
            <!-- GRADE -->
            <b-row>
              <b-col cols="4">Grade</b-col>
              <b-col cols="8">{{
                eventData.client.clientProfile
                  ? eventData.client.clientProfile.grade
                  : 'N/A'
              }}</b-col>
            </b-row>

            <!--  -->
            <b-row>
              <b-col cols="4">Address</b-col>
              <b-col cols="8"
                >{{ eventData.client.street1 }} {{ eventData.client.street2 }}</b-col
              >
            </b-row>

            <!--  -->
            <div v-if="eventData.client.community" class="row">
              <b-col cols="4">Community</b-col>
              <b-col cols="8">{{ eventData.client.community }}</b-col>
            </div>

            <!--  -->
            <div v-if="eventData.client.gatecode" class="row">
              <b-col cols="4">Gate Code</b-col>
              <b-col cols="8">{{ eventData.client.gatecode }}</b-col>
            </div>

            <!--  -->
            <div v-if="eventData.client.phone.length" class="row">
              <b-col cols="4">Phone</b-col>
              <b-col cols="8">
                <div v-for="(phone, i) in eventData.client.phone" :key="i">
                  {{ phone.number }} ({{ phone.type }})
                </div>
              </b-col>
            </div>

            <!--  -->
            <div v-if="eventData.client.email" class="row">
              <b-col cols="4">Email</b-col>
              <b-col cols="8">{{ eventData.client.email }}</b-col>
            </div>

            <!--  -->
            <div v-if="eventData.referral_src" class="row">
              <b-col cols="4">Referral</b-col>
              <b-col cols="8">{{ eventData.referral_src }}</b-col>
            </div>

            <!--  -->
            <div v-if="eventData.directions">
              <hr />
              Notes / Directions
              <p class="my-3">{{ eventData.directions }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <h3 class="panel-title">Appointment Details</h3>
          <div class="apptDet">
            <!--  -->
            <b-row>
              <b-col cols="4">Status</b-col>
              <b-col cols="8">{{ eventData.statusLabel }}</b-col>
            </b-row>

            <!--  -->
            <b-row v-if="eventData.notes">
              <b-col class="text-info">{{ eventData.notes }}</b-col>
            </b-row>

            <!--  -->
            <b-row>
              <b-col cols="4">{{ eventData.consultation ? 'EC' : 'Tutor' }}</b-col>
              <b-col cols="8">{{ staffName }}</b-col>
            </b-row>
            <hr />

            <!--  -->
            <b-row>
              <b-col cols="4">Schedule</b-col>
              <b-col cols="8">@todo</b-col>
            </b-row>

            <!-- Billing Cycle -->
            <b-row>
              <b-col cols="4">Billing Cycle</b-col>
              <b-col cols="8">
                <router-link
                  v-if="eventData.subscription"
                  :to="{
                    name: 'BillingDetails',
                    params: { id: eventData.subscription.id },
                  }"
                >
                  #{{ eventData.subscription.id }}
                </router-link>
                <span v-else>N/A</span>
              </b-col>
            </b-row>

            <!-- Quoted Rate -->
            <b-row v-if="$can('read', 'Billing')">
              <b-col cols="4">Quoted Rate</b-col>
              <b-col cols="8"
                >${{ eventData.quotedRate }}/{{ eventData.quotedRateType }}</b-col
              >
            </b-row>

            <!-- service -->
            <b-row>
              <b-col cols="4">Subject</b-col>
              <b-col cols="8"
                >{{ eventData.serviceGroup ? eventData.serviceGroup.name : 'N/A' }}
                {{ eventData.service }}</b-col
              >
            </b-row>

            <!--  -->
            <b-row>
              <div class="col-sm-12 col-sm-offset-4" id="subscription_description">
                <p class="my-3">{{ eventData.fullDescription }}</p>
              </div>
            </b-row>

            <!--  -->
            <!-- b-row>
              <b-col cols="4">Assessment</b-col>
              <b-col cols="8">?@todo?</b-col>
            </b-row -->
            <hr />
          </div>
          <div v-if="eventData.confirmation">
            <hr />
            <h4>Confirmation Details</h4>
            <div class="apptConf">
              <div v-if="eventData.confirmation.posted.session.today.date">
                <b-row>
                  <b-col cols="4">
                    <b>This Appt:</b>
                  </b-col>
                  <b-col>
                    {{ eventData.confirmation.posted.session.today.date }}:
                    {{ eventData.confirmation.posted.session.today.time[0] }} -
                    {{ eventData.confirmation.posted.session.today.time[1] }}
                  </b-col>
                </b-row>
              </div>
              <div v-if="eventData.confirmation.posted.session.next.date">
                <b-row>
                  <b-col cols="4">
                    <b>Next Appt:</b>
                  </b-col>
                  <b-col>
                    {{ eventData.confirmation.posted.session.next.date }}:
                    {{ eventData.confirmation.posted.session.next.time[0] }} -
                    {{ eventData.confirmation.posted.session.next.time[1] }}
                  </b-col>
                </b-row>
              </div>
              <blockquote v-if="eventData.confirmation['session-notes']" class="mt-3">
                {{ eventData.confirmation['session-notes'] }}
              </blockquote>
              <b-row>
                <div class="col-sm-12">
                  <img
                    :src="'data:' + eventData.confirmation.signature"
                    alt="client signature"
                    class="img-responsive img-thumbnail"
                  />
                </div>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <ApptConfirmation
      v-else
      :appt-data="eventData"
      @cancel="showApptConfirmation = false"
    />
  </div>
  <div v-else>
    <p class="text-center">Loading {{ loadingMsg }}...</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApptConfirmation from '../clients/~ApptConfirmation.vue'

export default {
  name: 'EventInfo',
  components: {
    ApptConfirmation,
  },
  props: {
    eventData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: true,
    loadingMsg: null,
    showApptConfirmation: false,
  }),
  computed: {
    ...mapGetters(['groupContacts', 'clientContacts']),
    staffName() {
      if (!this.eventData.assigned.length) {
        return 'N/A'
      }
      return `${this.eventData.assigned[0].staff.profile.fname} ${this.eventData.assigned[0].staff.profile.lname}`
    },
    pc() {
      const defaultContact = {
        fname: '',
        lname: '',
      }
      return this.groupContacts.length
        ? {
            ...this.groupContacts[0].data,
            ...{
              phone: this.groupContacts[0].phones.length
                ? this.groupContacts[0].phones[0].number
                : null,
            },
          }
        : this.clientContacts.length
        ? {
            ...this.clientContacts[0].data,
            ...{
              phone: this.clientContacts[0].phones.length
                ? this.clientContacts[0].phones[0].number
                : null,
            },
          }
        : defaultContact
    },
    pcName() {
      return `${this.pc.fname} ${this.pc.lname}`
    },
    pcPhone() {
      return this.pc.phone
    },
  },
  async mounted() {
    this.loading = true
    // this.loadingMsg = 'Phone Numbers'
    // await this.getClientPhones({
    //   pk: this.eventData.client.id,
    // })
    // console.log(this.$store.getters.phones)
    // console.log(this.eventData)
    this.loadingMsg = 'Contact Info'
    await this.doGetPrimaryContact()
    this.loading = false
    this.loadingMsg = null
  },
  methods: {
    ...mapActions(['getContactRecords', 'resetContactRecords', 'getClientPhones']),
    async doGetPrimaryContact() {
      await this.resetContactRecords()
      const params = {}
      if (this.eventData.client.groups.length) {
        params.groupId = this.eventData.client.groupsData[0].groupId
      }
      params.clientId = this.eventData.client.id
      console.log('doGetPrimaryContact params', params)
      await this.getContactRecords(params)
    },
  },
}
</script>
