<template>
  <b-row v-if="dateTime">
    <b-col
      cols="12"
      :lg="hasTimeTo ? 4 : 6"
      class="dtp-date-launch"
      id="session-today-date-launch"
    >
      <b-form-group
        :label="`${$t('label.date')}:`"
        label-class="pt-2"
        class="mb-2 mb-lg-0"
        :label-sr-only="labelSrOnly"
      >
        <b-form-datepicker v-model="dateTime.date" locale="en"></b-form-datepicker>
      </b-form-group>
    </b-col>
    <b-col
      :cols="12"
      :sm="hasTimeTo ? 6 : 12"
      :lg="hasTimeTo ? 4 : 6"
      class="dtp-time-launch"
      id="session-today-time-0-launch"
    >
      <b-form-group
        :label="`${$t('label.timeStart')}:`"
        label-class="pt-2"
        class="mb-2 mb-md-0"
        :label-sr-only="labelSrOnly"
        ><b-form-timepicker
          v-model="dateTime.time[0]"
          minutes-step="15"
          locale="en"
          required
        ></b-form-timepicker
      ></b-form-group>
    </b-col>
    <b-col
      v-if="hasTimeTo"
      cols="12"
      sm="6"
      lg="4"
      class="dtp-time-launch"
      id="session-today-time-1-launch"
    >
      <b-form-group
        :label="`${$t('label.timeEnd')}:`"
        label-class="pt-2"
        class="mb-0"
        :label-sr-only="labelSrOnly"
        ><b-form-timepicker
          v-model="dateTime.time[1]"
          minutes-step="15"
          locale="en"
          required
        ></b-form-timepicker
      ></b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'DateTime',
  props: {
    value: {
      type: [Object, String],
      default: () => ({
        date: '',
        time: [],
      }),
    },
    labelSrOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateTime: {
        date: '',
        time: [],
      },
    }
  },
  computed: {
    objectType() {
      return typeof this.value === 'object'
    },
    hasTimeTo() {
      return this.objectType && this.dateTime && this.dateTime.time.length > 1
    },
  },
  watch: {
    value: {
      handler(value) {
        if (!this.objectType) {
          // convert datetime value to object
          const nv = this.$moment(value).format('YYYY-MM-DDTHH:mm:ss').split('T')
          value = {
            date: nv[0],
            time: [nv[1]],
          }
        }
        this.dateTime = value
      },
      immediate: true,
    },
    dateTime: {
      handler(value) {
        this.$emit('input', this.objectType ? value : `${value.date}T${value.time[0]}`)
      },
      deep: true,
    },
  },
}
</script>
