<template>
  <div v-if="apptData.id" :id="`appt-${apptData.id}-confirm`" class="appt-confirm">
    <h3>{{ $t('label.apptConfirmation') }}</h3>

    <b-alert class="my-3" variant="info" show>
      <p class="mb-0 text-center">
        {{ $t('client.apptConfirmation') }}
      </p>
    </b-alert>

    <b-container>
      <label>{{ $t('label.apptConfirmationToday') }}</label>
      <DateTime v-model="session.today" class="border py-3" />

      <div class="my-3">
        <b-form-checkbox
          id="inc_next_session"
          name="inc_next_session"
          v-model="incNextSession"
        >
          Set Next Session
        </b-form-checkbox>
      </div>

      <div v-if="incNextSession">
        <label>{{ $t('label.apptConfirmationNext') }}</label>
        <DateTime v-model="session.next" class="border py-3" />
      </div>
    </b-container>

    <b-form-textarea
      v-model="notes"
      class="my-3"
      rows="2"
      max-rows="16"
      placeholder="Optional notes..."
    ></b-form-textarea>
    <b-alert variant="warning" class="my-3 text-center" show>
      <span class="mr-2"><i class="fa fa-exclamation"></i></span>
      {{ $t('client.apptConfirmationSignature') }}</b-alert
    >
    <VPerfectSignature
      class="border"
      :stroke-options="strokeOptions"
      ref="signaturePad"
    />
    <div class="d-flex justify-content-between mt-2">
      <b-button @click="onCancel" variant="danger">Cancel</b-button>
      <b-button @click="onClearSignature" variant="warning">Clear</b-button>
      <b-button @click="onSaveSignature" variant="success">Accept</b-button>
    </div>
  </div>
</template>

<script>
import DateTime from '../../components/DateTime.vue'
import { VPerfectSignature } from 'v-perfect-signature'

export default {
  name: 'ApptConfirmation',
  props: {
    apptData: {
      type: Object,
      required: true,
    },
  },
  components: {
    DateTime,
    VPerfectSignature,
  },
  data: () => ({
    incNextSession: false,
    session: {
      today: {
        date: null,
        time: [],
      },
      next: {
        date: null,
        time: [],
      },
      'session-notes': null,
    },
    notes: null,
    strokeOptions: {
      size: 16,
      thinning: 0.75,
      smoothing: 0.5,
      streamline: 0.5,
    },
  }),
  watch: {
    apptData: {
      handler(value) {
        this.session.today = {
          date: value.startDate,
          time: [
            value.startTime,
            this.$d(`${value.startDate} ${value.startTime}`)
              .add(value.duration, 'hours')
              .format('HH:mm:ss'),
          ],
        }
        this.session.next = {
          date: this.$d(value.startDate).add(1, 'week').format('YYYY-MM-DD'),
          time: [value.startTime],
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.$refs.signaturePad.clear()
  },
  destroyed() {},
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onClearSignature() {
      this.$refs.signaturePad.clear()
    },
    onSaveSignature() {
      this.$bvModal
        .msgBoxConfirm(
          'I have read and acknoledged that the above information is complete and correct.',
          {
            cancelTitle: 'Go Back',
            okTitle: 'Confirm',
            okVariant: 'success',
          }
        )
        .then((confirmed) => {
          if (!confirmed) return
          const signature = this.$refs.signaturePad.toDataURL()
          this.$store.dispatch('postClientAppointmentSignature', {
            posted: {
              session: this.session,
            },
            signature,
            nextJobId: null,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.appt-confirm {
  label {
    font-weight: bold;
  }
}
</style>
